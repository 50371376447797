/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMivr = /* GraphQL */ `
  query GetMivr($ID: ID!, $AttributeType: String!) {
    getMivr(ID: $ID, AttributeType: $AttributeType) {
      ID
      AttributeType
      AttributeValue
      DataType
      FacilityID
      Timestamp
      Text
      PhoneNumber
      Status
      YearDate
      HospitalGuid
      MultiAddresses
    }
  }
`;
export const listMivrs = /* GraphQL */ `
  query ListMivrs(
    $ID: ID
    $AttributeType: ModelStringKeyConditionInput
    $filter: ModelMivrFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMivrs(
      ID: $ID
      AttributeType: $AttributeType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ID
        AttributeType
        AttributeValue
        DataType
        FacilityID
        Timestamp
        Text
        PhoneNumber
        Status
        YearDate
        HospitalGuid
        MultiAddresses
      }
      nextToken
    }
  }
`;
export const getMivrResponse = /* GraphQL */ `
  query GetMivrResponse($ContactID: ID!, $Timestamp: String!) {
    getMivrResponse(ContactID: $ContactID, Timestamp: $Timestamp) {
      ContactID
      Timestamp
      DataType
      FacilityID
      CustomerPhoneNumber
      TransferTarget
      CustomerName
      ResponseMenu
      LastResponseMenu
      Message
      ResponseTime
      ConnectionTime
      TransferTime
      SMSSuccessCount
      SMSFailCount
      Transferred
      Status
      Memo
      CheckFlag
      AudioFileUrl
      TranscribeText
      GptSummary
      SMSMessages
    }
  }
`;
export const listMivrResponses = /* GraphQL */ `
  query ListMivrResponses(
    $ContactID: ID
    $Timestamp: ModelStringKeyConditionInput
    $filter: ModelMivrResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMivrResponses(
      ContactID: $ContactID
      Timestamp: $Timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ContactID
        Timestamp
        DataType
        FacilityID
        CustomerPhoneNumber
        CustomerName
        ResponseMenu
        LastResponseMenu
        Message
        ResponseTime
        ConnectionTime
        TransferTime
        SMSSuccessCount
        SMSFailCount
        Transferred
        Status
      }
      nextToken
    }
  }
`;
export const getMivrMonthlyFee = /* GraphQL */ `
  query GetMivrMonthlyFee($FacilityID: Int!, $Month: Int!) {
    getMivrMonthlyFee(FacilityID: $FacilityID, Month: $Month) {
      FacilityID
      Month
      DataType
      Received
      Completed
      Transferred
      CompleteRatio
      SMSCount
      TotalResponseTime
      AverageResponseTime
      ResponseFee
      SMSFee
      TotalFee
      SMSApiCount
      SMSApiFee
      SVCount
      SVFee
      KRCount
      KRFee
      WMCount
      WMFee
      IVCount
      IVFee
      AutoOutboundCount
    }
  }
`;
export const listMivrMonthlyFees = /* GraphQL */ `
  query ListMivrMonthlyFees(
    $FacilityID: Int
    $Month: ModelIntKeyConditionInput
    $filter: ModelMivrMonthlyFeeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMivrMonthlyFees(
      FacilityID: $FacilityID
      Month: $Month
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        FacilityID
        Month
        DataType
        Received
        Completed
        Transferred
        CompleteRatio
        SMSCount
        NotifySMSCount
        TotalResponseTime
        AverageResponseTime
        ResponseFee
        SMSFee
        TotalFee
        TransferRecordingFee
        TransferRecordingTime
        Status
        SMSApiCount
        SMSApiFee
        SVCount
        SVFee
        KRCount
        KRFee
        WMCount
        WMFee
        IVCount
        IVFee
        AutoOutboundCount
      }
      nextToken
    }
  }
`;
export const getMivrOperationLog = /* GraphQL */ `
  query GetMivrOperationLog($ID: ID!) {
    getMivrOperationLog(ID: $ID) {
      ID
      Timestamp
      LogLevel
      Component
      Function
      User
      Param
      UserAgent
      IP
      Version
    }
  }
`;
export const listMivrOperationLogs = /* GraphQL */ `
  query ListMivrOperationLogs(
    $ID: ID
    $filter: ModelMivrOperationLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMivrOperationLogs(
      ID: $ID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ID
        Timestamp
        LogLevel
        Component
        Function
        User
        Param
        UserAgent
        IP
        Version
      }
      nextToken
    }
  }
`;
export const getMivrSequence = /* GraphQL */ `
  query GetMivrSequence($Name: String!) {
    getMivrSequence(Name: $Name) {
      Name
      CurrentNumber
    }
  }
`;
export const listMivrSequences = /* GraphQL */ `
  query ListMivrSequences(
    $Name: String
    $filter: ModelMivrSequenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMivrSequences(
      Name: $Name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Name
        CurrentNumber
      }
      nextToken
    }
  }
`;
export const getMivrSMSHistory = /* GraphQL */ `
  query GetMivrSMSHistory($ID: String!) {
    getMivrSMSHistory(ID: $ID) {
      ID
      MessageId
      RequestId
      HospitalGuid
      PhoneNumber
      Text
      SmsCount
      BounceUrl
      RequestingService
      SmsStatus
      Timestamp
      DataType
      FacilityID
      IsSent
      Provider
      ShortMessageId
    }
  }
`;
export const listMivrSMSHistorys = /* GraphQL */ `
  query ListMivrSMSHistorys(
    $ID: String
    $filter: ModelMivrSMSHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMivrSMSHistorys(
      ID: $ID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ID
        MessageId
        RequestId
        HospitalGuid
        PhoneNumber
        Text
        SmsCount
        BounceUrl
        RequestingService
        SmsStatus
        Timestamp
        DataType
        FacilityID
        IsSent
        Provider
        ShortMessageId
      }
      nextToken
    }
  }
`;
export const listMivrDataByDataType = /* GraphQL */ `
  query ListMivrDataByDataType(
    $DataType: String
    $FacilityID: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrDataByDataType(
      DataType: $DataType
      FacilityID: $FacilityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        AttributeType
        AttributeValue
        DataType
        FacilityID
        Timestamp
        Text
        PhoneNumber
        Status
        YearDate
        HospitalGuid
        MultiAddresses
      }
      nextToken
    }
  }
`;
export const listMivrDataByFacilityID = /* GraphQL */ `
  query ListMivrDataByFacilityID(
    $FacilityID: Int
    $Timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrDataByFacilityID(
      FacilityID: $FacilityID
      Timestamp: $Timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        AttributeType
        AttributeValue
        DataType
        FacilityID
        Timestamp
        Text
        PhoneNumber
        Status
        YearDate
        HospitalGuid
        MultiAddresses
      }
      nextToken
    }
  }
`;
export const listMivrDataByPhoneNumber = /* GraphQL */ `
  query ListMivrDataByPhoneNumber(
    $PhoneNumber: String
    $FacilityID: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrDataByPhoneNumber(
      PhoneNumber: $PhoneNumber
      FacilityID: $FacilityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        AttributeType
        AttributeValue
        DataType
        FacilityID
        Timestamp
        Text
        PhoneNumber
        Status
        YearDate
        HospitalGuid
        MultiAddresses
      }
      nextToken
    }
  }
`;
export const listMivrDataByAttributeType = /* GraphQL */ `
  query ListMivrDataByAttributeType(
    $AttributeType: String
    $AttributeValue: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrDataByAttributeType(
      AttributeType: $AttributeType
      AttributeValue: $AttributeValue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        AttributeType
        AttributeValue
        DataType
        FacilityID
        Timestamp
        Text
        PhoneNumber
        Status
        YearDate
        HospitalGuid
        MultiAddresses
      }
      nextToken
    }
  }
`;
export const listMivrDataByFacilityIDAndYearDate = /* GraphQL */ `
  query ListMivrDataByFacilityIDAndYearDate(
    $FacilityID: Int
    $YearDate: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrDataByFacilityIDAndYearDate(
      FacilityID: $FacilityID
      YearDate: $YearDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        AttributeType
        AttributeValue
        DataType
        FacilityID
        Timestamp
        Text
        PhoneNumber
        Status
        YearDate
        HospitalGuid
        MultiAddresses
      }
      nextToken
    }
  }
`;
export const listMivrResponsesByFacilityID = /* GraphQL */ `
  query ListMivrResponsesByFacilityID(
    $FacilityID: Int
    $Timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrResponsesByFacilityID(
      FacilityID: $FacilityID
      Timestamp: $Timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ContactID
        Timestamp
        DataType
        FacilityID
        CustomerPhoneNumber
        TransferTarget
        CustomerName
        ResponseMenu
        LastResponseMenu
        Message
        ResponseTime
        ConnectionTime
        TransferTime
        SMSSuccessCount
        SMSFailCount
        Transferred
        Status
        Memo
        CheckFlag
        AudioFileUrl
        TranscribeText
        GptSummary
        SMSMessages
      }
      nextToken
    }
  }
`;
export const listMivrResponsesByDataType = /* GraphQL */ `
  query ListMivrResponsesByDataType(
    $DataType: String
    $Timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrResponsesByDataType(
      DataType: $DataType
      Timestamp: $Timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ContactID
        Timestamp
        DataType
        FacilityID
        CustomerPhoneNumber
        CustomerName
        ResponseMenu
        LastResponseMenu
        Message
        ResponseTime
        ConnectionTime
        TransferTime
        SMSSuccessCount
        SMSFailCount
        Transferred
        Status
      }
      nextToken
    }
  }
`;
export const listMivrMonthlyFeeByMonth = /* GraphQL */ `
  query ListMivrMonthlyFeeByMonth(
    $Month: Int
    $sortDirection: ModelSortDirection
    $filter: ModelMivrMonthlyFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrMonthlyFeeByMonth(
      Month: $Month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        FacilityID
        Month
        DataType
        Received
        Completed
        Transferred
        CompleteRatio
        SMSCount
        TotalResponseTime
        AverageResponseTime
        ResponseFee
        SMSFee
        TotalFee
        SMSApiCount
        SMSApiFee
        SVCount
        SVFee
        KRCount
        KRFee
        WMCount
        WMFee
        IVCount
        IVFee
        AutoOutboundCount
      }
      nextToken
    }
  }
`;
export const listMonthlyFeeByDataType = /* GraphQL */ `
  query ListMonthlyFeeByDataType(
    $DataType: String
    $Month: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrMonthlyFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonthlyFeeByDataType(
      DataType: $DataType
      Month: $Month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        FacilityID
        Month
        DataType
        Received
        Completed
        Transferred
        CompleteRatio
        SMSCount
        NotifySMSCount
        TotalResponseTime
        AverageResponseTime
        ResponseFee
        SMSFee
        TotalFee
        TransferRecordingFee
        TransferRecordingTime
        Status
        DayOfUse
        SMSApiCount
        SMSApiFee
        SVCount
        SVFee
        KRCount
        KRFee
        WMCount
        WMFee
        IVCount
        IVFee
        AutoOutboundCount
      }
      nextToken
    }
  }
`;
export const listMivrOperationLogByLogLevel = /* GraphQL */ `
  query ListMivrOperationLogByLogLevel(
    $LogLevel: String
    $Timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrOperationLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrOperationLogByLogLevel(
      LogLevel: $LogLevel
      Timestamp: $Timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        Timestamp
        LogLevel
        Component
        Function
        User
        Param
        UserAgent
        IP
        Version
      }
      nextToken
    }
  }
`;
export const listMivrSMSHistoryByDataType = /* GraphQL */ `
  query ListMivrSMSHistoryByDataType(
    $DataType: String
    $Timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrSMSHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrSMSHistoryByDataType(
      DataType: $DataType
      Timestamp: $Timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        MessageId
        RequestId
        HospitalGuid
        PhoneNumber
        Text
        SmsCount
        BounceUrl
        RequestingService
        SmsStatus
        Timestamp
        DataType
        FacilityID
        IsSent
        Provider
        ShortMessageId
        FailureReason
      }
      nextToken
    }
  }
`;
export const listMivrSMSHistoryByMessageId = /* GraphQL */ `
  query ListMivrSMSHistoryByMessageId(
    $MessageId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMivrSMSHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrSMSHistoryByMessageId(
      MessageId: $MessageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        MessageId
        RequestId
        HospitalGuid
        PhoneNumber
        Text
        SmsCount
        BounceUrl
        RequestingService
        SmsStatus
        Timestamp
        DataType
        FacilityID
        IsSent
        Provider
        ShortMessageId
        FailureReason
      }
      nextToken
    }
  }
`;
export const listMivrSMSHistoryByRequestingService = /* GraphQL */ `
  query ListMivrSMSHistoryByRequestingService(
    $RequestingService: String
    $PhoneNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMivrSMSHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrSMSHistoryByRequestingService(
      RequestingService: $RequestingService
      PhoneNumber: $PhoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        MessageId
        RequestId
        HospitalGuid
        PhoneNumber
        Text
        SmsCount
        BounceUrl
        RequestingService
        SmsStatus
        Timestamp
        DataType
        FacilityID
        IsSent
        Provider
        ShortMessageId
        FailureReason
      }
      nextToken
    }
  }
`;
export const listMivrSMSHistoryByIsSent = /* GraphQL */ `
  query ListMivrSMSHistoryByIsSent(
    $IsSent: String
    $sortDirection: ModelSortDirection
    $filter: ModelMivrSMSHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrSMSHistoryByIsSent(
      IsSent: $IsSent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        MessageId
        RequestId
        HospitalGuid
        PhoneNumber
        Text
        SmsCount
        BounceUrl
        RequestingService
        SmsStatus
        Timestamp
        DataType
        FacilityID
        IsSent
        Provider
        ShortMessageId
        FailureReason
      }
      nextToken
    }
  }
`;
export const listMivrSMSHistoryByShortMessageId = /* GraphQL */ `
  query ListMivrSMSHistoryByShortMessageId(
    $ShortMessageId: Int
    $sortDirection: ModelSortDirection
    $filter: ModelMivrSMSHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMivrSMSHistoryByShortMessageId(
      ShortMessageId: $ShortMessageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        MessageId
        RequestId
        HospitalGuid
        PhoneNumber
        Text
        SmsCount
        BounceUrl
        RequestingService
        SmsStatus
        Timestamp
        DataType
        FacilityID
        IsSent
        Provider
        ShortMessageId
        FailureReason
      }
      nextToken
    }
  }
`;
