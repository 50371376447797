/**
 * 共通JS
 */
import store from "../store"
import $ from 'jquery';
import 'datatables';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listMivrDataByAttributeType, listMivrs, listMivrDataByDataType, getMivr } from '@/graphql/queries';
import { sequenceIncrement, createMivrOperationLog, createMivr } from '@/graphql/mutations';
import axios from 'axios'
import dayjs from "dayjs";

// Ajaxのレスポンスハンドリング
// function ajaxResponseHandling(xhr) {
//       var responseUrl = xhr.getResponseHeader('Response-Url') || "";
//       console.log('●●●レスポンス：'+ responseUrl);
//       // リダイレクト結果がログイン画面の場合、Ajax反映領域に埋め込まれないよう再リダイレクト
//       if (responseUrl.indexOf('/login') > -1) {
//           console.log('●●●要ログイン');
//           document.location.href = "login";
//       // リダイレクト結果がシステムメンテナンス画面の場合、Ajax反映領域に埋め込まれないよう再リダイレクト
//       } else if (responseUrl.indexOf('/system_maintenance') > -1) {
//           console.log('●●●システムメンテナンス中');
//           // システムメンテナンス画面への直接遷移は禁止しているため、ログイン画面でのメンテチェックを行う
//           document.location.href = "login";
//       }
//       // 上記以外は何も行わない
//   }

// ポップアップイメージ作成
export function addPopUpImage(id, type) {
    if (type == "W") {
      $(id).removeClass().addClass("fas fa-exclamation-triangle fa-3x fa-yellow");
    }
    if (type == "E") {
      $(id).removeClass().addClass("fas fa-times-circle  fa-3x fa-red");
    }
    if (type == "Q") {
      $(id).removeClass().addClass("fas fa-question-circle  fa-3x fa-grey");
    }
    if (type == "I") {
      $(id).removeClass().addClass("fas fa-info-circle  fa-3x fa-blue");
    }
}
  
export function showModal(id) {
    $(id).modal({
        keyboard: false,
        backdrop: "static",
    });
}

// navbar改行表示時の高さ調整
$(window).on("load resize", function () {
// navbarの高さを取得する
var height = $(".navbar").height();
height = height + 0;
// bodyのpaddingにnavbar分の高さを設定する
$("body").css("padding-top", height);
});

export function init() {
    // console.log("初期化処理開始");
    // navbarの高さを取得する
    var height = $(".navbar").height();
    height = height + 0;
    // bodyのpaddingにnavbar分の高さを設定する
    $("body").css("padding-top", height);
    navDatatableFix();
    $('[data-toggle="tooltip"]').tooltip();
    // console.log("初期化処理終了");
}

export function initCalendar() {
    // console.log("カレンダー初期化処理開始");
    // navbarの高さを取得する
    var height = $(".navbar").height();
    height = height + 0;
    // bodyのpaddingにnavbar分の高さを設定する
    $("body").css("padding-top", height);
    navDatatableFix();
}

export function initMonthCalendar() {
    let month_list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

    let today = new Date();
    //現在の年月を文字列の状態で取得する(例：20211)
    let now_date = String(today.getFullYear()) + String((today.getMonth() +1));
    //現在の年月が5文字(例：20211~20219)のような場合、月を01表記に整形(例：202101~202109)
    if(now_date.length == 5){
        now_date = now_date.slice(0, 4) + "0" + now_date.slice(-1);
    }
    let num_now_date = Number(now_date);

    //2021年4月にスタート年月を設定
    let onset = 202104;

    //スタート年月(onset)～現在(num_now_date)のプルダウンを繰り返しで生成する
    while (onset <= num_now_date) {
        //onsetの月部分が13になった時、年に+1年、月を1月にする
        //例）202113→202201
        let value = String(onset);
        if (value.slice(-2) == 13) {
            onset = onset + 100 -12;
            value = String(onset);
        }
        //年と月
        let set1 = value.slice(0, 4);
        let set2 = Number(value.slice(-2)) -1;

        $('#rangeStart').append('<option value="' + value + '">' + set1 + '/' + month_list[set2] + '</option>');
        $('#rangeEnd').append('<option value="' + value + '">' + set1 + '/' + month_list[set2] + '</option>');

        onset++;
    }

    //3ヶ月前をrangeStartの初期選択値とする
    let rangeStartIndex = $('#rangeStart').children('option').length - 3;

    //当月をrangeEndの初期選択値とする
    let rangeEndIndex = $('#rangeEnd').children('option').length - 1;

    $('#rangeStart').prop("selectedIndex", rangeStartIndex);
    $('#rangeEnd').prop("selectedIndex", rangeEndIndex);
}

export function drawDatatable(tableData,descNum) {
    // console.log("Datatable描画処理開始");

    // 検索結果表
    $("#dataList").DataTable({
        autoWidth: false,
        data: tableData.data,
        columnDefs: tableData.columnDefs,
        order: [
        [descNum, "desc"],
        ],
        aLengthMenu: [
        [5, 10, 25, 50, 100, -1],
        [5, 10, 25, 50, 100, "All"],
        ],
        iDisplayLength: 10,
        language: {
            emptyTable: "データが登録されていません。",
            info: "_TOTAL_ 件中 _START_ 件から _END_ 件までを表示",
            infoEmpty: "",
            infoFiltered: "(_MAX_ 件からの絞り込み表示)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "1ページあたりの表示件数： _MENU_",
            loadingRecords: "ロード中",
            processing: "処理中...",
            search: "",
            searchPlaceholder: '\uf002 検索ワードを入力',
            zeroRecords: "該当するデータが見つかりませんでした。",
            paginate: {
                first: "先頭",
                previous: "前へ",
                next: "次へ",
                last: "末尾",
            },
        },
        fnDrawCallback: function() {
            // ページネーション後にtooltipを再適用
            $('[data-toggle="tooltip"]').tooltip();
        }
    });

    $('[data-toggle="tooltip"]').tooltip();

    // console.log("Datatable描画処理終了");
}

export function redrawDatatable(data) {
    // console.log("Datatable再描画処理開始");

    let datatable = $("#dataList").DataTable();
    datatable.clear().draw();
    datatable.rows.add(data); // Add new data
    datatable.columns.adjust().draw(); // Redraw the DataTable

    $('[data-toggle="tooltip"]').tooltip();

    // console.log("Datatable再描画処理終了");
}

export function redrawDatatableWithFixedPage(data) {
    const datatable = $("#dataList").DataTable()
    const currentPage = datatable.page()

    datatable.clear().draw()
    datatable.rows.add(data)
    datatable.columns.adjust().draw()

    datatable.page(currentPage).draw(false)

    $('[data-toggle="tooltip"]').tooltip()
}

// bootstrapのナビゲーションドロップダウンのイベントとdatatableのイベントが競合することの回避
export function navDatatableFix() {
    $("nav .dropdown").each(function (index, el) {
        $(el).on("click", function () {
        $(el).find(".dropdown-toggle").dropdown("toggle");
        });
    });
}

/**
 * 操作ログの登録
 * @param {*} operation 
 * @param {*} param 
 */
export async function addOperationLogs(logLevel, component, func, param, e=null) {
    // エラーデータ
    if( e != null ) {
        Object.assign( param, { exception: e } );
    }

    // ユーザー
    let user = 'null';
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        user = cognitoUser.username;
    } catch (error) {
        // 何もしない
    }

    // シーケンス番号を取得
    let seq = await NextSequenceNumber('OperationLog');
    if(seq < 0) {
        return;
    }
    // Timestamp(UNIXタイムスタンプ)を取得
    const date = new Date() ;
    const timestamp = date.getTime() ;
    // IPアドレスを取得
    const ipAddress = await getIpAddress();
    // バージョン情報を取得
    const version = process.env.VUE_APP_VERSION;

    let logId = `log-${seq}`;
    let input = {
        ID: logId,
        Timestamp: timestamp,
        LogLevel: logLevel,
        Component: component,
        Function: func,
        User: user,
        Param: param,
        UserAgent: navigator.userAgent,
        IP: ipAddress,
        Version: version
    };
    try {
        await API.graphql(graphqlOperation( createMivrOperationLog, { input: input } ));
    } catch(error) {
        // console.log(error);
        // 何もしない。
    }
}


export async function getIpAddress() {
    let ip = "";
    try {
        const response = await axios.get('https://api.ipify.org?format=json', { timeout: 5000 });
        ip = response.data.ip;
    } catch (e) {
        ip = "getIpAddress error: " + e;
    }
    return ip;
}

/**
 * 次のシーケンス番号を取得
 * @param {*} sequenceName 
 */
 export async function NextSequenceNumber(sequenceName) {
    let input = {
        Name: sequenceName,
    };
    try {
        let result = await API.graphql(graphqlOperation( sequenceIncrement, input ));
        return result.data.sequenceIncrement.CurrentNumber;
    } catch(error) {
        // console.log(error);
        return -1;
    }
}

/**
 * Vuexへユーザー情報（医療機関ID・ユーザータイプ）をセット
 * @param {*} username 
 */
export async function setUserData(username, type) {
    // console.log(`setUserData`);
    let result = [];
    let loginCondition = { AttributeType: 'UserID', AttributeValue: {eq: username}, limit: 10 };
    try {
        result = await API.graphql(graphqlOperation( listMivrDataByAttributeType, loginCondition ));
        let loginUser = result.data.listMivrDataByAttributeType.items[0];
        let userCondition = { ID: loginUser.ID, limit: 10 };
        try {
            result = await API.graphql(graphqlOperation( listMivrs, userCondition ));
            let userDataList = result.data.listMivrs.items;
            let userTypeCode = userDataList.filter(x => x.AttributeType === 'UserType')[0].AttributeValue;
            let facilityID = userDataList.filter(x => x.AttributeType === 'UserType')[0].FacilityID;
            // console.log(userTypeCode);
            let userType = '';
            switch(type) {
                case 'admin':
                    if (userTypeCode == '0') {
                        userType = 'admin';
                        store.commit('setUserType', userType);
                        store.commit('setFacilityID', facilityID);
                        return true;
                    } else {
                        return false;
                    }
                case 'user':
                    if (userTypeCode == '1' || userTypeCode == '2') {
                        userType = 'user';
                        store.commit('setUserType', userType);
                        store.commit('setFacilityID', facilityID);
                        return true;
                    } else {
                        return false;
                    }
                default:
                    return false;
            }
        } catch(error) {
            // 異常系操作ログの登録
            let param = {fuction: 'commit state'};
            addOperationLogs( 'Error', 'common.js', 'setUserData', param, error );
            return false;
        }
    } catch(error) {
        // 異常系操作ログの登録
        let param = {fuction: 'get user data'};
        addOperationLogs( 'Error', 'common.js', 'setUserData', param, error );
        return false;
    }
}

/**
 * 医療機関IDを取得
 */
 export async function getFacilityId() {
    if (store.getters.facilityID) {
        return store.getters.facilityID;
    } else {
        let user = "";
        try {
            user = await Auth.currentAuthenticatedUser();
        } catch (e) {
            // 未ログイン時 The user is not authenticated
            // 未ログイン時にこのgetFacilityId()が呼ばれることは無いはずだが他箇所に倣ってtry-catchした
        }
        let result = [];
        // 未ログイン時はuserがnullになるため、null以外の時にFacilityIDの取得を行う
        if (user) {
            // currentUserInfo()を使用していたときはusernameがnullの際に別医療機関のデータが取得されていたのでサインアウトさせる
            if (!user.username) {
                try {
                    let param = {
                        "message": 'currentAuthenticatedUser().username is null.'
                    };
                    addOperationLogs('Error', 'common.js', 'getFacilityId', param);
                    await Auth.signOut();
                    location.reload();
                } catch (error) {
                    console.log(error);
                } finally {
                    // eslint-disable-next-line no-unsafe-finally
                    return -1;
                }
            }
            let loginCondition = { AttributeType: 'UserID', AttributeValue: {eq: user.username}, limit: 10 };
            try {
                result = await API.graphql(graphqlOperation( listMivrDataByAttributeType, loginCondition ));
                let loginUser = result.data.listMivrDataByAttributeType.items[0];
                return loginUser.FacilityID;
            } catch(error) {
                // 異常系操作ログの登録
                let param = {fuction: 'get user data'};
                addOperationLogs( 'Error', 'common.js', 'getFacilityId', param, error );
                return -1;
            }
        }
    }
}

export const fetchHospitalGuid = async (facilityId) => {
    if (store.getters.hospitalGuid) return store.getters.hospitalGuid

    const id = `Facility-${facilityId}`
    const { data } = await API.graphql({ query: getMivr, variables: { ID: id, AttributeType: "HospitalGuid" } })

    if (!data.getMivr) {
        throw new Error(`HospitalGuidの取得ができませんでした。FacilityId: ${facilityId}`)
    }

    const hospitalGuid = data.getMivr.AttributeValue
    store.commit("setHospitalGuid", hospitalGuid)

    return hospitalGuid
}

/**
 * ユーザータイプを取得
 * storeされていた場合はstoreから、storeされていない場合はDBから情報をfetchする
 */
 export async function fetchUserType(username) {
    if (store.getters.userType) {
        return store.getters.userType;
    } else {
        let result = [];
        let loginCondition = { AttributeType: 'UserID', AttributeValue: {eq: username}, limit: 10 };
        try {
            result = await API.graphql(graphqlOperation( listMivrDataByAttributeType, loginCondition ));
            let loginUser = result.data.listMivrDataByAttributeType.items[0];
            let userCondition = { ID: loginUser.ID, limit: 10 };
            try {
                result = await API.graphql(graphqlOperation( listMivrs, userCondition ));
                let userDataList = result.data.listMivrs.items;
                let userTypeCode = userDataList.filter(x => x.AttributeType === 'UserType')[0].AttributeValue;
                let userType = userTypeCode == 0 ? "admin" : "user";

                // fetch~というメソッド名だけど、次以降のアクセスのために、情報とったらここでstoreさせてください
                store.commit('setUserType', userType);
                return userType;
            } catch(error) {
                // 異常系操作ログの登録
                let param = {fuction: 'get user data'};
                addOperationLogs( 'Error', 'common.js', 'getUserType', param, error );
                return -1;
            }
        } catch(error) {
            // 異常系操作ログの登録
            let param = {fuction: 'get login user data'};
            addOperationLogs( 'Error', 'common.js', 'getUserType', param, error );
            return -1;
        }
    }
}

/**
 * 音声設定を取得(Mizuki/Takumi/Kazuha/Tomoko)
 * storeされていた場合はstoreから、storeされていない場合はDBから情報をfetchする
 */
 export async function fetchVoiceType(facilityId) {
    if (store.getters.voiceType) {
        return store.getters.voiceType;
    } else {
        let voiceType = "Mizuki";
        let result = [];
        let condition = { DataType: 'Facility', FacilityID: {eq: facilityId}, limit: 100000 };
        try {
            result = await API.graphql(graphqlOperation( listMivrDataByDataType, condition ));
            let facilityDataList = result.data.listMivrDataByDataType.items;
            try {
                voiceType = facilityDataList.filter(x => x.AttributeType === 'VoiceType')[0].AttributeValue;
            } catch(e) {
                // VoiceTypeのAttributeがないだけ(問題なし)
                console.log("non VoiceType")
            }
            // fetch~というメソッド名だけど、次以降のアクセスのために、情報とったらここでstoreさせてください
            store.commit('setVoiceType', voiceType);
            return voiceType;
        } catch(error) {
            // 異常系操作ログの登録
            let param = {};
            addOperationLogs( 'Error', 'common.js', 'fetchVoiceType', param, error );
            return "Mizuki";
        }
    }
}

/**
 * 乗算処理
 * JavaScriptによる小数計算の誤差を無くすため以下の手順で乗算を行う
 * 1.小数点削除
 * 2.計算
 * 3.小数点を戻す
 */
export function multiply(value1, value2) {
    // 小数点を削除
    let intValue1 = Number((value1 + '').replace('.', ''));
    let intValue2 = Number((value2 + '').replace('.', ''));
    // 小数点を戻すための桁数算出
    let decimalLength = getDecimalLength(value1) + getDecimalLength(value2);
    // 乗算実行
    let result = (intValue1 * intValue2) / Math.pow(10, decimalLength);
    return result;
}

/**
 * 引数の数値の小数点以下の桁数を返却する
 */
export function getDecimalLength(value) {
    let list = (value + '').split('.');
    let result = 0;
    if (list[1] !== undefined  && list[1].length > 0) {
        result = list[1].length;
    }
    return result;
}

/**
 * サポートに通知しておきたいユーザーの動作を記録しておく
 */
export async function registerForNotification(facilityId, attributeValue) {
    // シーケンス番号を取得
    let seq = await NextSequenceNumber('SupportNotification');
    if (seq < 0) {
        return;
    }
    let id = `SupportNotification-${seq}`;
    let timestamp = new Date().getTime();
    let input = {
        ID: id,
        AttributeType: 'NotificationType',
        AttributeValue: attributeValue,
        DataType: 'SupportNotification',
        FacilityID: facilityId,
        Timestamp: timestamp
    };
    try {
        await API.graphql(graphqlOperation( createMivr, { input: input } ));
    } catch(e) {
        // クリティカルではないが一応Infoレベルでログに残しておく
        let param = {
            "サポート通知用データ": JSON.stringify(input)
        };
        addOperationLogs( 'Info', 'common.js', 'registerForNotification', param, e );
    }
}

export function updateChart(responseList, chartdata) {
    // 円グラフの背景カラー色
    const COLOR_LIST = ['#ff6384', '#36a2eb', "#7fffd4", "#ffd700", "#dda0dd", "#00ced1", "#ffdead", "#66cdaa", "#ff6347"];

    let responseMenuAggregate = [];
    let addedList = [];
    responseList.sort((a, b) => {
        if (a.LastResponseMenu < b.LastResponseMenu) return -1;
        if (a.LastResponseMenu > b.LastResponseMenu) return 1;
        return 0;
    });
    for (let i = 0; i < responseList.length; i++) {
        if (addedList.indexOf(responseList[i].LastResponseMenu) < 0) {
            let json = {};
            json[responseList[i].LastResponseMenu] = 1;
            responseMenuAggregate.push(json);
            addedList.push(responseList[i].LastResponseMenu);
        } else {
            let index = addedList.indexOf(responseList[i].LastResponseMenu);
            responseMenuAggregate[index][responseList[i].LastResponseMenu] = parseInt(responseMenuAggregate[index][responseList[i].LastResponseMenu]) + 1;
        }
    }
    chartdata['datacollection']['labels'] = [];
    chartdata['datacollection']['datasets'][0].backgroundColor = [];
    chartdata['datacollection']['datasets'][0].data = [];
    // ラベルの領域が大きくなることでグラフが小さくならないように、8を起点にモーダルのheightを増やしていく
    // 8以下はheight400px それ以上はメニューが1個増えるごとに高さを10pxずつ増やす
    let modalHeight_px = 400;
    if (responseMenuAggregate.length >= 8) {
        modalHeight_px += (responseMenuAggregate.length - 7) * 10;
    }
    $("#pie-chart").css("min-height", modalHeight_px);
    for (let i = 0; i < responseMenuAggregate.length; i++) {
        let key = Object.keys(responseMenuAggregate[i]);
        let menuCount = parseInt(responseMenuAggregate[i][key]);
        chartdata['datacollection']['labels'].push(key == "" ? "選択メニューなし" : key);
        chartdata['datacollection']['datasets'][0].backgroundColor.push(COLOR_LIST[i % 9]);
        chartdata['datacollection']['datasets'][0].data.push(menuCount);
    }
    return chartdata
}

export const formatDate = (date) => {
    return dayjs(date).format('YYYY/MM/DD HH:mm')
}

export const getHrsAndMins = (time) => {
    return { hours: time.getHours(), minutes: time.getMinutes() }
}

export const getByteSize = (arg) => {
  const rawArg = JSON.stringify(arg)
  return new Blob([rawArg]).size
}

export const isAcceptableByteSizeForAmazonConnect = (inputByteSize) => {
    const AMAZON_CONNECT_BYTE_SIZE_LIMIT = 32000
    const ACCEPTABLE_BYTE_SIZE = AMAZON_CONNECT_BYTE_SIZE_LIMIT - 2000

    return inputByteSize <= ACCEPTABLE_BYTE_SIZE
}

const getBucketFileName = (audioFileUrl, cloudfrontDomain) => {
    if (!audioFileUrl.includes(cloudfrontDomain)) throw new Error("audioFileUrlに期待するCloudFrontのドメイン名が含まれていないため、バケット名を取得できませんでした。")
  
    const fileName = audioFileUrl.split(cloudfrontDomain + "/")[1]
    return fileName
}

const getPresignedUrlParams = (audioFileUrl) => {
    const cloudFrontDomains = {
        voiceMail: process.env.VUE_APP_CLOUD_FRONT_VOICE_MAIL_DOMAIN,
        transferRecording: process.env.VUE_APP_CLOUD_FRONT_TRANSFER_RECORDING_DOMAIN
    }
    const bucketNames = {
        voiceMail: process.env.VUE_APP_VOICE_MAIL_BUCKET,
        transferRecording: process.env.VUE_APP_TRANSFER_RECORDING_BUCKET
    }

    // cloudFrontDomainsとbucketNamesのキーは同じ
    const key = Object.keys(cloudFrontDomains).find(key => audioFileUrl.includes(cloudFrontDomains[key]))

    if (!key) throw new Error("audioFileUrlに期待するCloudFrontのドメイン名が含まれていないため、パラメーターを取得できませんでした。")

    return {
        bucketName: bucketNames[key],
        bucketFileName: getBucketFileName(audioFileUrl, cloudFrontDomains[key]),
    }
}

export const fetchPresignedUrl = async (audioFileUrl) => {
    const presignedApiUrl = process.env.VUE_APP_PRESIGNED_API_URL
    
    const { bucketName, bucketFileName } = getPresignedUrlParams(audioFileUrl)

    const currentUserPoolUser = await Auth.currentUserPoolUser()
    const token = currentUserPoolUser.signInUserSession.idToken.jwtToken
    const data = { bucketName, fileName: bucketFileName }
    const axiosConfig = { headers: { Authorization: token } }
    
    const res = await axios.post(presignedApiUrl, data, axiosConfig)

    return res.data.presignedURL
}

export const fetchTmpPresignedUrl = async (audioFileUrl, recordedTime) => {
    const presignedApiUrl = process.env.VUE_APP_TMP_PRESIGNED_API_URL
    
    const { bucketName, bucketFileName } = getPresignedUrlParams(audioFileUrl)

    const apiKey = process.env.VUE_APP_TMP_PRESIGNED_API_KEY

    const data = { bucketName, fileName: bucketFileName, recordedTime }
    
    const res = await axios.post(presignedApiUrl, data, { headers: { "x-api-key": apiKey } })

    return res.data.presignedURL
}

export const isThisMonth = (date) => dayjs().isSame(dayjs(date), "month")